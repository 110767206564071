<template>
  <div data-cy="wizard-context">
    <b-row no-gutters>
      <b-col>
        <h2 class="text-center">
          TRAIN YOUR OWN AI FOR TOP RANKING ARTICLES
        </h2>
      </b-col>
    </b-row>

    <div class="bg-dark rounded-lg p-4 mt-50 ml-auto mr-auto" style="max-width: 825px">
      <img src="@/assets/character/smiling.png" class="float-left" width="75" />

      <p>
        Hey there, I'm Louis AI.<br />
        <strong>First, I need to know more about you!</strong>
      </p>

      <form-builder
        :state-machine="stateMachine"
        :components-list="componentsList"
        :custom-validators="{}"
        :next-button="{ text: 'Continue', variant: 'outline-primary' }"
        :is-sending="isSending"
        @submit="nextStep"
        class="form-wizard form-wizard-teammate"
      />
    </div>

    <div class="rounded-lg p-4 mt-50 ml-auto mr-auto" style="max-width:   825px">
      <div>
        <h5 class="mb-0 text-uppercase">Louis AI's advice</h5>
        <p class="bigger">
          Once you provide your URL, I'll take a quick look at your website, and conduct a thorough analysis to deliver the best
          work, tailored to your needs.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { TRIGGER_EVENT } from "@/store/actions";

import { FormBuilder } from "ui-stepper";
import { BRow, BCol } from "bootstrap-vue";
import cloneDeep from "lodash/cloneDeep";

import { LANDING_STATE_MACHINE_SEO_URL } from "@/constants/project";

export default {
  name: "LandingSettings",
  components: { BRow, BCol, FormBuilder },
  data: () => ({
    formData: {},

    stateMachine: null,
    componentsList: {},

    isSending: false,
  }),
  created() {
    this.stateMachine = cloneDeep(LANDING_STATE_MACHINE_SEO_URL);
  },
  methods: {
    ...mapActions("trackings", { triggerEvent: TRIGGER_EVENT }),
    async nextStep() {
      this.isSending = true;

      const { formData } = this.stateMachine.context;

      this.formData = cloneDeep(formData);

      this.$emit("update:settings", formData);
      this.$emit("next-step");
      // this.triggerEvent({ event: "landing_step", step: value, vueInstance: this });

      this.isSending = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_settings";

.landing-context {
  small {
    font-size: 0.7em;
    display: block;
    color: $gray-darker;
  }
}

legend {
  width: auto;
  font-size: 0.8em;
  color: gray;
  font-weight: 600;
  margin: 0;
}

fieldset {
  border: 2px solid rgb(246 246 246);
  padding: 10px 25px 25px 25px;
  margin-bottom: 1em;
}
</style>
