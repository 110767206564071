<template>
  <div>
    <b-row no-gutters>
      <b-col cols="0" md="1" lg="1" />

      <b-col cols="12" md="10" lg="10">
        <div class="d-block text-left pt-3 pb-3">
          <b-img
            :src="require('@/assets/character/smiling.png')"
            width="120"
            height="120"
            alt="Smiling character"
            class="float-left"
            rounded
            fluid
          />

          <h1 class="d-inline-block text-left rotate-effect">
            <span class="text-uppercase">Meet Louis AI</span><br />
            <span class="mt-n4 d-inline-block font-handwriting">And His Team</span>
          </h1>
        </div>

        <b-alert v-if="errorMessage" show variant="danger">
          <p class="text-white">{{ errorMessage }}</p>
        </b-alert>

        <wizard-settings v-else-if="currentStep === 0" :settings.sync="settings" @next-step="currentStep++" class="p-3" />

        <wizard-suggestions
          v-else-if="currentStep === 1"
          :settings.sync="settings"
          :blog-post.sync="blogPost"
          :is-sending="false"
          @next-step="currentStep++"
          class="p-3"
        />

        <wizard-register v-else-if="currentStep === 2" @success="triggerCeateProject" :item="blogPost" class="p-3" />

        <div v-else-if="currentStep === 3" class="m-4">
          <vue-lottie-player
            name="scooterAnim"
            loop
            :animation-data="require('@/assets/animations/spinner.json')"
            class="ml-auto mr-auto"
            style="height: 100px"
          />

          <p class="text-center">
            <em class="text-white"> {{ "Redirecting..." | __t("status__redirecting") }}</em>
          </p>
        </div>
      </b-col>

      <b-col cols="0" md="1" lg="1" />
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { CREATE, GET, TRIGGER_EVENT } from "@/store/actions";

import { BRow, BCol, BImg, BAlert } from "bootstrap-vue";
import VueLottiePlayer from "vue-lottie-player";

import { CHATBOT_MESSAGE_TYPE, CHATBOT_ROLES } from "@/constants/aiChat";
import { DEFAULT_REPLY, TASKS } from "@/constants/tasks";

import { sanitizeTextWithContext } from "@/helpers/ai";

import WizardSettings from "./Settings";
import WizardSuggestions from "./Suggestions";
import WizardRegister from "./Register";

export default {
  name: "Landing",
  components: {
    BRow,
    BCol,
    BImg,
    BAlert,
    VueLottiePlayer,
    WizardSettings,
    WizardSuggestions,
    WizardRegister,
  },
  computed: {
    ...mapState({ userLanguage: (state) => state.locales.language }),
    ...mapState({ mode: (state) => state.customize["dark-mode"] }),
    ...mapGetters("projects", ["projectsSorted"]),

    isMobile() {
      const { value } = this.$screen.alias;
      return value === "xs" || value === "sm" || value === "md";
    },
  },
  data: () => ({
    settings: {},
    blogPost: null,

    currentStep: 0,

    errorMessage: null,
    errorCode: null,

    isSending: false,
    isMagicWriting: false,
  }),
  created() {
    this.triggerEvent({
      event: "landing_step",
      step: 0,
      vueInstance: this,
    });
  },
  watch: {
    currentStep() {
      this.$nextTick(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
    },
  },
  methods: {
    ...mapActions("trackings", { triggerEvent: TRIGGER_EVENT }),
    ...mapActions("projects", { createProject: CREATE, getProject: GET }),
    ...mapActions("projectsTasks", { createTask: CREATE }),
    ...mapActions("projectsTasksDiscussions", { createMessage: CREATE }),

    async triggerCeateProject() {
      this.currentStep++;

      this.errorCode = null;
      this.isSending = true;

      const { settings, blogPost } = this;

      let { name, nameShorten } = settings;

      name = nameShorten ? nameShorten : name || "No Name";

      const { isSuccess, projectId, errorCode } = await this.createProject({ name });

      if (isSuccess && projectId) {
        const actionId = TASKS.WRITE_BLOG_POST_SEO;

        await this.triggerCreateTask({ projectId, actionId, data: blogPost });
        return;
      }

      this.errorCode = errorCode;
      this.isSending = false;
    },
    async triggerCreateTask({ projectId, actionId, data }) {
      const { id: taskId } = await this.createTask({ projectId, actionId });

      const { firstMessage, storeDispatch, defaultData = {} } = DEFAULT_REPLY[actionId];

      const format = CHATBOT_MESSAGE_TYPE.FILE;
      const role = CHATBOT_ROLES.USER;
      const content = sanitizeTextWithContext(firstMessage, data);

      await this.createMessage({ projectId, taskId, role, format, content });
      this.$store.dispatch(storeDispatch, { projectId, taskId, ...defaultData, ...data });

      this.$router.push({ name: "ProjectTask", params: { projectId, taskId } });
    },
  },
};
</script>

<style scoped lang="scss">
@use "sass:map";
@import "@/assets/scss/_settings";
.control-buttons {
  .back-button {
    position: absolute;
    @media (max-width: map.get($grid-breakpoints, "md")) {
      position: relative;
    }
  }
}
</style>
<style>
.rotate-effect {
  transform: rotate(-3deg);
}
</style>
